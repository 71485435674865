<template>
  <div>
    <div class="navbar navbar-expand-lg navbar-dark">
      <div class="d-flex flex-1 d-lg-none">
        <button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
          <i class="icon-paragraph-justify3" />
        </button>
      </div>
      <div class="d-flex flex-1 justify-content-end align-items-center">
        <ul class="navbar-nav flex-row">
          <li
            class="nav-item nav-item-dropdown-lg dropdown dropdown-user h-100"
          >
            <a
              href="#"
              class="navbar-nav-link navbar-nav-link-toggler dropdown-toggle d-inline-flex align-items-center h-100"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="d-lg-inline-block">{{ userName }}</span>
            </a>

            <div class="dropdown-menu dropdown-menu-right">
              <button
                class="dropdown-item"
                @click="showAccountModal = true"
                title="Meu Perfil"
              >
                <i class="icon-user" /> Meu Perfil
              </button>
              <div class="dropdown-divider" />
              <button class="dropdown-item" @click="logout" title="Sair">
                <i class="icon-switch2" /> Sair
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <account-modal
      v-if="showAccountModal"
      @closeModal="showAccountModal = !showAccountModal"
    />
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'
import AccountModal from '@/components/commons/AccountModal.vue'

export default {
  name: 'Navbar',

  components: {
    AccountModal,
  },

  data() {
    return {
      showAccountModal: false,
    }
  },

  computed: {
    userName() {
      return this.$store.getters.user.name
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        localStorage.clear()
        this.$router.push(PATHS.BASE_URL)
      })
    },
  },
}
</script>
