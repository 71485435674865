import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveBatch({ commit }, batch) {
      try {
        return await api.post('/soil/batchs', batch)
      } catch (error) {
        throw error
      }
    },

    async getBatchs({ commit }, data) {
      try {
        const params = {}

        if (data && data.status) {
          params.status = data.status
        }

        const response = await api.get('/soil/batchs', { params })

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getBatch({ commit }, batchId) {
      try {
        const response = await api.get(`/soil/batchs/${batchId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async cancelBatch({ commit }, batchId) {
      try {
        return await api.put(`/soil/batchs/${batchId}/cancel`)
      } catch (error) {
        throw error
      }
    },

    async getShimentsAvaliablesToBatch({ commit }) {
      try {
        const response = await api.get('/soil/batchs/shipments')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getGenericTemplate({ commit }, data) {
      try {
        const response = await api.get(
          `/soil/batchs/${data.batchId}/templates/${data.analysisId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
