import api from '@/services/api'

export default {
  state: {
    farmSelected: {
      id: 0,
      inceresFarmId: null,
    },
    reloadDetailsFarm: false,
  },

  mutations: {
    SET_FARM_SELECTED(state, farm) {
      state.farmSelected = {
        id: farm ? farm.id : 0,
        inceresFarmId: farm ? farm.inceresFarmId : null,
      }
    },

    SET_RELOAD_DETAILS_FARM(state, payload) {
      state.reloadDetailsFarm = payload
    },
  },

  actions: {
    async saveFarm({ commit }, data) {
      try {
        return await api.post(`clients/${data.clientId}/farms`, data.farm)
      } catch (error) {
        throw error
      }
    },

    async getFarms({ commit }, data) {
      try {
        let url = `/clients/${data.clientId}/farms`

        if (data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getFarm({ commit }, data) {
      try {
        const response = await api.get(
          `/clients/${data.clientId}/farms/${data.farmId}`,
        )

        const farm = response && response.data ? response.data : null

        commit('SET_FARM_SELECTED', farm)

        return farm
      } catch (error) {
        throw error
      }
    },

    async updateFarm({ commit }, data) {
      try {
        return await api.put(
          `/clients/${data.clientId}/farms/${data.farmId}`,
          data.farm,
        )
      } catch (error) {
        throw error
      }
    },

    setReloadDetailsFarm: ({ commit }, status) => {
      commit('SET_RELOAD_DETAILS_FARM', status)
    },
  },

  getters: {
    farmSelected: (state) => state.farmSelected,
    reloadDetailsFarm: (state) => state.reloadDetailsFarm,
  },
}
