import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveLaboratory({ commit }, laboratory) {
      try {
        return await api.post('/precision/laboratorys', laboratory)
      } catch (error) {
        throw error
      }
    },

    async getLaboratorys({ commit }, data) {
      try {
        let url = `/precision/laboratorys`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getLaboratory({ commit }, laboratoryId) {
      try {
        const response = await api.get(`/precision/laboratorys/${laboratoryId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateLaboratory({ commit }, laboratory) {
      try {
        return await api.put(
          `/precision/laboratorys/${laboratory.id}`,
          laboratory,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
