import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveSampler({ commit }, sampler) {
      try {
        return await api.post('/precision/samplers', sampler)
      } catch (error) {
        throw error
      }
    },

    async getSamplers({ commit }, requestId) {
      try {
        let url = `/precision/samplers`

        if (requestId) {
          url += `?requestId=${requestId}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSampler({ commit }, samplerId) {
      try {
        const response = await api.get(`/precision/samplers/${samplerId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async deleteSampler({ commit }, samplerId) {
      try {
        return await api.delete(`/precision/samplers/${samplerId}`)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
