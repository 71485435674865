<template>
  <div
    class="sidebar sidebar-dark sidebar-main sidebar-expand-lg sidebar-main-resized"
  >
    <div class="navbar navbar-dark bg-dark-100 navbar-static border-0">
      <div class="navbar-brand flex-fill wmin-0">
        <router-link to="/home" class="d-inline-block">
          <img
            src="@/assets/img/logo_nav.png"
            class="sidebar-resize-hide"
            alt="Logo"
          />
          <img
            src="@/assets/img/logo_mini_md.png"
            class="sidebar-resize-show"
            alt="Logo"
          />
        </router-link>
      </div>

      <ul class="navbar-nav align-self-center ml-auto sidebar-resize-hide">
        <li class="nav-item dropdown">
          <button
            type="button"
            class="btn btn-outline-light-100 text-white border-transparent btn-icon rounded-pill btn-sm sidebar-control sidebar-main-resize d-none d-lg-inline-flex"
            title="Fixar/Desafixar o Menu"
          >
            <i class="icon-transmission" />
          </button>

          <button
            type="button"
            class="btn btn-outline-light-100 text-white border-transparent btn-icon rounded-pill btn-sm sidebar-mobile-main-toggle d-lg-none"
            title="Fechar o Menu"
          >
            <i class="icon-cross2" />
          </button>
        </li>
      </ul>
    </div>

    <div class="sidebar-content">
      <div class="sidebar-section mt-2">
        <ul class="nav nav-sidebar" data-nav-type="accordion">
          <li class="nav-item-header pt-0">
            <div class="text-uppercase font-size-xs line-height-xs">Menu</div>
            <i class="icon-menu" title="Main" />
          </li>
          <li
            class="nav-item nav-item-submenu"
            v-show="getModuleUserByCode('1')"
          >
            <a class="nav-link" title="BackOffice">
              <i class="fas fa-cogs" /><span>BackOffice</span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="BackOffice">
              <li class="nav-item" v-show="getModuleUserByCode('1.01')">
                <router-link
                  to="/backoffice/clients"
                  class="nav-link"
                  title="Clientes"
                >
                  <i class="fas fa-users" /><span>Clientes</span>
                </router-link>
              </li>
            </ul>
          </li>
          <li
            class="nav-item"
            v-show="getModuleUserByCode('2') || userIsSampler"
          >
            <router-link
              to="/precision/home"
              class="nav-link"
              title="Agricultura de Precisão"
            >
              <img
                src="@/assets/img/modules/agricultura-precisao-minimalista.png"
                alt="Agricultura de Precisão"
              />
              <span>Agricultura de Precisão</span>
            </router-link>
          </li>
          <li v-if="1 === 2" class="nav-item" v-show="getModuleUserByCode('3')">
            <router-link to="/soil/home" class="nav-link" title="Solos">
              <img
                src="@/assets/img/modules/experimental-minimalista.png"
                alt="Solos"
              />
              <span>Solos</span>
            </router-link>
          </li>
          <li
            class="nav-item nav-item-submenu"
            v-show="getModuleUserByCode('0')"
          >
            <a class="nav-link" title="Área Administrativa">
              <i class="icon-user-lock" /><span>Área Administrativa</span>
            </a>
            <ul
              class="nav nav-group-sub"
              data-submenu-title="Área Administrativa"
            >
              <li class="nav-item" v-show="getModuleUserByCode('0.01')">
                <router-link
                  to="/administration/users"
                  class="nav-link"
                  title="Usuários"
                >
                  <i class="icon-users" /><span>Usuários</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar',

  computed: {
    ...mapGetters(['getModuleUserByCode', 'userIsSampler']),
  },
}
</script>

<style scoped>
.navbar-brand {
  padding: 0.575rem 0.2rem;
}

.navbar-brand img {
  height: 2.125rem;
}

.nav-sidebar .nav-link img {
  width: 1.4rem;
  margin-right: 0.9rem;
}

@media (min-width: 992px) {
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-link
    svg,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-link
    img {
    margin-left: 0;
    margin-right: 0;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    svg,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-link
    svg,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    img,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-link
    img {
    position: static;
    display: block;
  }
}
</style>
