import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveDepth({ commit }, depth) {
      try {
        return await api.post('/soil/depths', depth)
      } catch (error) {
        throw error
      }
    },

    async getDepths({ commit }, data) {
      try {
        let url = `/soil/depths`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getDepth({ commit }, depthId) {
      try {
        const response = await api.get(`/soil/depths/${depthId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateDepth({ commit }, depth) {
      try {
        return await api.put(`/soil/depths/${depth.id}`, depth)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
