import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveArea({ commit }, data) {
      try {
        return await api.post(
          `clients/${data.clientId}/farms/${data.farmId}/areas`,
          data.area,
        )
      } catch (error) {
        throw error
      }
    },

    async getAreas({ commit }, data) {
      try {
        let url = `/clients/${data.clientId}/farms/${data.farmId}/areas`

        if (data.status) {
          url += `?status=${data.status}`
        }
        if (data.requestId) {
          url += `&requestId=${data.requestId}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getArea({ commit }, data) {
      try {
        const response = await api.get(
          `/clients/${data.clientId}/farms/${data.farmId}/areas/${data.areaId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateArea({ commit }, data) {
      try {
        return await api.put(
          `/clients/${data.clientId}/farms/${data.farmId}/areas/${data.areaId}`,
          data.area,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
