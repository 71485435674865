import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async loginInceres({ commit }, credentials) {
      try {
        return await api.post(`/inceres/login`, credentials)
      } catch (error) {
        throw error
      }
    },

    async syncClients({ commit }) {
      try {
        return await api.get('/inceres/sync/clients')
      } catch (error) {
        throw error
      }
    },

    async syncFarms({ commit }, inceresClientId) {
      try {
        return await api.get(`/inceres/sync/clients/${inceresClientId}/farms`)
      } catch (error) {
        throw error
      }
    },

    async syncAreas({ commit }, parameters) {
      try {
        return await api.get(
          `/inceres/sync/clients/${parameters.inceresClientId}/farms/${parameters.inceresFarmId}/areas`,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
