import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveRequest({ commit }, request) {
      try {
        return await api.post('/precision/requests', request)
      } catch (error) {
        throw error
      }
    },

    async getRequests({ commit }) {
      try {
        const response = await api.get('/precision/requests')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getRequest({ commit }, requestId) {
      try {
        const response = await api.get(`/precision/requests/${requestId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateRequest({ commit }, request) {
      try {
        return await api.put(`/precision/requests/${request.id}`, request)
      } catch (error) {
        throw error
      }
    },

    async addSampleRequest({ commit }, data) {
      try {
        return await api.post(
          `/precision/requests/${data.requestId}/samplers`,
          data,
        )
      } catch (error) {
        throw error
      }
    },

    async removeSampleRequest({ commit }, data) {
      try {
        return await api.delete(
          `/precision/requests/${data.requestId}/samplers/${data.requestSamplerId}`,
        )
      } catch (error) {
        throw error
      }
    },

    async addAreaRequest({ commit }, data) {
      try {
        return await api.post(
          `/precision/requests/${data.requestId}/areas`,
          data,
        )
      } catch (error) {
        throw error
      }
    },

    async getRequestAreas({ commit }, requestId) {
      try {
        const response = await api.get(`/precision/requests/${requestId}/areas`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getRequestArea({ commit }, data) {
      try {
        const response = await api.get(
          `/precision/requests/${data.requestId}/areas/${data.requestAreaId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async insertMeasureRequestArea({ commit }, data) {
      try {
        return await api.post(
          `/precision/requests/${data.requestId}/areas/${data.requestAreaId}`,
          data,
        )
      } catch (error) {
        throw error
      }
    },

    async cancelMeasureRequestArea({ commit }, data) {
      try {
        return await api.put(
          `/precision/requests/${data.requestId}/areas/${data.requestAreaId}`,
          data,
        )
      } catch (error) {
        throw error
      }
    },

    async associateLaboratorRequestArea({ commit }, data) {
      try {
        return await api.put(
          `/precision/requests/${data.requestId}/areas`,
          data,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
