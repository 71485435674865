import auth from './modules/auth/auth'
import users from './modules/user/users'
import modules from './modules/module/modules'
import commons from './modules/common/commons'
import importation from './modules/inseres/importation'
import clients from './modules/client/clients'
import farms from './modules/client/farms'
import areas from './modules/client/areas'
import precision from './modules/precision/precision'
import precisionHarvests from './modules/precision/harvests'
import precisionSamplers from './modules/precision/samplers'
import precisionLaboratorys from './modules/precision/laboratorys'
import precisionRequests from './modules/precision/requests'
import precisionEquipments from './modules/precision/equipments'
import soilDepths from './modules/soil/depths'
import soilTypeOfAnalysis from './modules/soil/typeofanalysis'
import soilShipments from './modules/soil/shipments'
import soilBatchs from './modules/soil/batchs'
import soilAnalysis from './modules/soil/analysis'

export default {
  auth,
  users,
  modules,
  commons,
  importation,
  clients,
  farms,
  areas,
  precision,
  precisionHarvests,
  precisionSamplers,
  precisionLaboratorys,
  precisionRequests,
  precisionEquipments,
  soilDepths,
  soilTypeOfAnalysis,
  soilShipments,
  soilBatchs,
  soilAnalysis,
}
