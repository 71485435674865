import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getDashboard({ commit }, filters) {
      try {
        const params = {}

        if (filters.harvestId) {
          params.harvestId = filters.harvestId
        }
        if (filters.stateId) {
          params.stateId = filters.stateId
        }

        const response = await api.get('/precision/dashboard', { params })

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getReportOverview({ commit }, filters) {
      try {
        const params = {
          harvestId: filters.harvestId,
          stateId: filters.stateId,
          startDate: filters.startDate,
          endDate: filters.endDate,
        }

        const response = await api.get('/precision/reports/overview', {
          params,
        })

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getReportCollectionByClient({ commit }, filters) {
      try {
        const params = {
          harvestId: filters.harvestId,
          startDate: filters.startDate,
          endDate: filters.endDate,
          clientId: filters.clientId,
        }

        if (filters.farmId) {
          params.farmId = filters.farmId
        }
        if (filters.areaId) {
          params.areaId = filters.areaId
        }

        const response = await api.get(
          '/precision/reports/collection-by-client',
          {
            params,
          },
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getReportProductivityOfSamplers({ commit }, filters) {
      try {
        const params = {
          startDate: filters.startDate,
          endDate: filters.endDate,
        }

        if (filters.userId) {
          params.userId = filters.userId
        }

        const response = await api.get(
          '/precision/reports/productivity-of-samplers',
          {
            params,
          },
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getReportDestinationOfSamples({ commit }, filters) {
      try {
        const params = {
          startDate: filters.startDate,
          endDate: filters.endDate,
        }

        if (filters.laboratoryId) {
          params.laboratoryId = filters.laboratoryId
        }

        const response = await api.get(
          '/precision/reports/destination-of-samples',
          {
            params,
          },
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getReportStatusPlots({ commit }, filters) {
      try {
        const params = {
          harvestId: filters.harvestId,
          clientId: filters.clientId,
        }

        if (filters.farmId) {
          params.farmId = filters.farmId
        }

        const response = await api.get('/precision/reports/status-plots', {
          params,
        })

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getReportFarmsLocation({ commit }) {
      try {
        const response = await api.get('/precision/reports/farms-location')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getReportAreasLocation({ commit }) {
      try {
        const response = await api.get('/precision/reports/areas-location')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
