import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import PATHS from './routesMap'

import Auth from '../views/auth/Auth.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Auth',
    path: PATHS.AUTH,
    component: Auth,
  },
  {
    name: 'PasswordRecovery',
    path: PATHS.PASSWORD_RECOVERY,
    component: () =>
      import(
        /* webpackChunkName: "passwordRecovery" */ '../views/auth/PasswordRecovery.vue'
      ),
  },
  {
    name: 'NewPassword',
    path: PATHS.NEW_PASSWORD,
    component: () =>
      import(
        /* webpackChunkName: "newPassword" */ '../views/auth/NewPassword.vue'
      ),
  },
  {
    name: 'Home',
    path: PATHS.HOME,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Administration',
    path: PATHS.ADM,
    component: () =>
      import(
        /* webpackChunkName: "administration" */ '../views/administration/Administration.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '0',
    },
    children: [
      {
        name: 'AdministrationUsers',
        path: PATHS.ADM_USERS,
        component: () =>
          import(
            /* webpackChunkName: "administration" */ '../views/administration/users/Users.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '0.01.001',
        },
      },
    ],
  },
  {
    name: 'BackOffice',
    path: PATHS.BACKOFFICE,
    component: () =>
      import(
        /* webpackChunkName: "backOffice" */ '../views/backoffice/BackOffice.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '1',
    },
    children: [
      {
        name: 'BackOfficeClients',
        path: PATHS.BACKOFFICE_CLIENTS,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/clients/Clients.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
      {
        name: 'BackOfficeDetailsClient',
        path: PATHS.BACKOFFICE_DETAILS_CLIENT,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/clients/ClientDetails.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
      {
        name: 'BackOfficeDetailsFarm',
        path: PATHS.BACKOFFICE_DETAILS_FARM,
        component: () =>
          import(
            /* webpackChunkName: "backOffice" */ '../views/backoffice/clients/FarmDetails.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '1.01.001',
        },
      },
    ],
  },
  {
    name: 'Precision',
    path: PATHS.PRECISION,
    component: () =>
      import(
        /* webpackChunkName: "precision" */ '../views/precision/Precision.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: '2',
    },
    children: [
      {
        name: 'PrecisionHome',
        path: PATHS.PRECISION_HOME,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/home/PrecisionHome.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2',
        },
      },
      {
        name: 'PrecisionHarvests',
        path: PATHS.PRECISION_HARVESTS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/harvests/PrecisionHarvests.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.01.001',
        },
      },
      {
        name: 'PrecisionSamplers',
        path: PATHS.PRECISION_SAMPLERS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/samplers/PrecisionSamplers.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.02.001',
        },
      },
      {
        name: 'PrecisionLaboratorys',
        path: PATHS.PRECISION_LABORATORYS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/laboratorys/PrecisionLaboratorys.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.04.001',
        },
      },
      {
        name: 'PrecisionRequestList',
        path: PATHS.PRECISION_REQUESTS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/request/PrecisionRequestList.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.03.001',
        },
      },
      {
        name: 'PrecisionRequestNew',
        path: PATHS.PRECISION_REQUESTS_NEW,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/request/PrecisionRequestNew.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.03.002',
        },
      },
      {
        name: 'PrecisionRequestDetails',
        path: PATHS.PRECISION_REQUESTS_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/request/PrecisionRequestDetails.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.03.001',
        },
      },
      {
        name: 'PrecisionEquipments',
        path: PATHS.PRECISION_EQUIPMENTS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/equipments/PrecisionEquipmentsHome.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.06',
        },
      },
      {
        name: 'PrecisionEquipmentsList',
        path: PATHS.PRECISION_EQUIPMENTS_LIST,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/equipments/PrecisionEquipmentsList.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.06.001',
        },
      },
      {
        name: 'PrecisionEquipmentDetails',
        path: PATHS.PRECISION_EQUIPMENTS_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/equipments/PrecisionEquipmentDetails.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.06.001',
        },
      },
      {
        name: 'PrecisionEquipmentMaintenancePlan',
        path: PATHS.PRECISION_EQUIPMENTS_MAINTENANCE_PLANS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/equipments/PrecisionMaintenancePlans.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.06.001',
        },
      },
      {
        name: 'PrecisionReports',
        path: PATHS.PRECISION_REPORTS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/report/PrecisionReport.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.05',
        },
      },
      {
        name: 'PrecisionReportsOverview',
        path: PATHS.PRECISION_REPORTS_OVERVIEW,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/report/PrecisionReportOverview.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.05.001',
        },
      },
      {
        name: 'PrecisionReportsCollectionByClient',
        path: PATHS.PRECISION_REPORTS_COLLECTION_BY_CLIENT,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/report/PrecisionReportCollectionByClient.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.05.001',
        },
      },
      {
        name: 'PrecisionReportsProductivityOfSamplers',
        path: PATHS.PRECISION_REPORTS_PRODUCTIVITY_OF_SAMPLERS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/report/PrecisionReportProductivityOfSamplers.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.05.001',
        },
      },
      {
        name: 'PrecisionReportsDestinationOfSamples',
        path: PATHS.PRECISION_REPORTS_DESTINATION_OF_SAMPLES,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/report/PrecisionReportDestinationOfSamples.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.05.001',
        },
      },
      {
        name: 'PrecisionReportsStatusPlots',
        path: PATHS.PRECISION_REPORTS_STATUS_PLOTS,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/report/PrecisionReportStatusPlots.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.05.001',
        },
      },
      {
        name: 'PrecisionReportsFarmLocation',
        path: PATHS.PRECISION_REPORTS_FARM_LOCATION,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/report/PrecisionReportFarmLocation.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.05.001',
        },
      },
      {
        name: 'PrecisionReportsAreaLocation',
        path: PATHS.PRECISION_REPORTS_AREA_LOCATION,
        component: () =>
          import(
            /* webpackChunkName: "precision" */ '../views/precision/report/PrecisionReportAreaLocation.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '2.05.001',
        },
      },
    ],
  },
  {
    name: 'Soil',
    path: PATHS.SOIL,
    component: () =>
      import(/* webpackChunkName: "soil" */ '../views/soil/Soil.vue'),
    meta: {
      requiresAuth: true,
      permission: '3',
    },
    children: [
      {
        name: 'SoilHome',
        path: PATHS.SOIL_HOME,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/home/SoilHome.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3',
        },
      },
      {
        name: 'SoilDepth',
        path: PATHS.SOIL_DEPTHS,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/depths/SoilDepths.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.01.001',
        },
      },
      {
        name: 'SoilTypeOfAnalysis',
        path: PATHS.SOIL_TYPES_OF_ANALYSIS,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/typeofanalysis/SoilTypeOfAnalysis.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.02.001',
        },
      },
      {
        name: 'SoilShipments',
        path: PATHS.SOIL_SHIPMENTS,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/shipments/SoilShipments.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.03.001',
        },
      },
      {
        name: 'SoilShipmentNew',
        path: PATHS.SOIL_SHIPMENTS_NEW,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/shipments/SoilShipmentNew.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.03.002',
        },
      },
      {
        name: 'SoilShipmentDetails',
        path: PATHS.SOIL_SHIPMENTS_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/shipments/SoilShipmentDetails.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.03.001',
        },
      },
      {
        name: 'SoilBatchs',
        path: PATHS.SOIL_BATCHS,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/batchs/SoilBatchs.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.04.001',
        },
      },
      {
        name: 'SoilBatchNew',
        path: PATHS.SOIL_BATCHS_NEW,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/batchs/SoilBatchNew.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.04.002',
        },
      },
      {
        name: 'SoilBatchDetails',
        path: PATHS.SOIL_BATCHS_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/batchs/SoilBatchDetails.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.04.001',
        },
      },
      {
        name: 'SoilAnalysis',
        path: PATHS.SOIL_ANALYSIS,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/analysis/SoilAnalysis.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.05.001',
        },
      },
      {
        name: 'SoilAnalysisNew',
        path: PATHS.SOIL_ANALYSIS_NEW,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/analysis/SoilAnalysisNew.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.05.001',
        },
      },
      {
        name: 'SoilAnalysisDetails',
        path: PATHS.SOIL_ANALYSIS_BATCHS,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/analysis/SoilAnalysisDetails.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.05.001',
        },
      },
      {
        name: 'SoilAnalysisAtomicAbsorption',
        path: PATHS.SOIL_ANALYSIS_ATOMIC_ABSORPTION,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/analysis/SoilAnalysisAtomicAbsorption.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.05.001',
        },
      },
      {
        name: 'SoilAnalysisSpectrophotometry',
        path: PATHS.SOIL_ANALYSIS_SPECTROPHOTOMETRY,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/analysis/SoilAnalysisSpectrophotometry.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.05.001',
        },
      },
      {
        name: 'SoilAnalysisTitration',
        path: PATHS.SOIL_ANALYSIS_TITRATION,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/analysis/SoilAnalysisTitration.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.05.001',
        },
      },
      {
        name: 'SoilAnalysisResult',
        path: PATHS.SOIL_ANALYSIS_RESULT,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/analysis/SoilAnalysisResult.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.05.001',
        },
      },
      {
        name: 'SoilAnalysisReport',
        path: PATHS.SOIL_ANALYSIS_REPORT,
        component: () =>
          import(
            /* webpackChunkName: "soil" */ '../views/soil/analysis/SoilAnalysisReport.vue'
          ),
        meta: {
          requiresAuth: true,
          permission: '3.05.001',
        },
      },
    ],
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (store.getters.isLoggedIn && to.name === 'Auth') {
    next({ name: 'Home' })
  } else if (to.meta.requiresAuth) {
    if (!store.getters.isLoggedIn && !localStorage.getItem('token')) {
      next({ name: 'Auth' })
    } else {
      if (to.meta.permission !== undefined) {
        if (!store.getters.getModuleUserByCode(to.meta.permission)) {
          if (!store.getters.userIsSampler) {
            next({ name: 'Unauthorized' })
          }
          if (store.getters.userIsSampler && !to.path.includes('precision')) {
            next({ name: 'Unauthorized' })
          }
        }
      }
      next()
    }
  } else {
    next()
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedIn && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.AUTH)
  }
})

export default router
