const BASE_URL = '/'
const AUTH = BASE_URL
const PASSWORD_RECOVERY = `${BASE_URL}password-recovery`
const NEW_PASSWORD = `${BASE_URL}new-password/:key`
const HOME = `${BASE_URL}home`
const ADM = `${BASE_URL}administration`
const ADM_USERS = `${ADM}/users`
const BACKOFFICE = `${BASE_URL}backoffice`
const BACKOFFICE_CLIENTS = `${BACKOFFICE}/clients`
const BACKOFFICE_DETAILS_CLIENT = `${BACKOFFICE_CLIENTS}/:clientId`
const BACKOFFICE_DETAILS_FARM = `${BACKOFFICE_DETAILS_CLIENT}/farm/:farmId`
const PRECISION = `${BASE_URL}precision`
const PRECISION_HOME = `${PRECISION}/home`
const PRECISION_HARVESTS = `${PRECISION}/harvests`
const PRECISION_SAMPLERS = `${PRECISION}/samplers`
const PRECISION_LABORATORYS = `${PRECISION}/laboratorys`
const PRECISION_REQUESTS = `${PRECISION}/requests`
const PRECISION_REQUESTS_NEW = `${PRECISION_REQUESTS}/new`
const PRECISION_REQUESTS_DETAILS = `${PRECISION_REQUESTS}/:requestId`
const PRECISION_EQUIPMENTS = `${PRECISION}/equipments`
const PRECISION_EQUIPMENTS_LIST = `${PRECISION}/equipments/list`
const PRECISION_EQUIPMENTS_DETAILS = `${PRECISION_EQUIPMENTS_LIST}/:equipmentId`
const PRECISION_EQUIPMENTS_MAINTENANCE_PLANS = `${PRECISION}/equipments/maintenance-plans`
const PRECISION_REPORTS = `${PRECISION}/reports`
const PRECISION_REPORTS_OVERVIEW = `${PRECISION_REPORTS}/overview`
const PRECISION_REPORTS_COLLECTION_BY_CLIENT = `${PRECISION_REPORTS}/collection-by-client`
const PRECISION_REPORTS_PRODUCTIVITY_OF_SAMPLERS = `${PRECISION_REPORTS}/productivity-of-samplers`
const PRECISION_REPORTS_DESTINATION_OF_SAMPLES = `${PRECISION_REPORTS}/destination-of-samples`
const PRECISION_REPORTS_STATUS_PLOTS = `${PRECISION_REPORTS}/status-plots`
const PRECISION_REPORTS_FARM_LOCATION = `${PRECISION_REPORTS}/farm-location`
const PRECISION_REPORTS_AREA_LOCATION = `${PRECISION_REPORTS}/area-location`
const SOIL = `${BASE_URL}soil`
const SOIL_HOME = `${SOIL}/home`
const SOIL_DEPTHS = `${SOIL}/depths`
const SOIL_TYPES_OF_ANALYSIS = `${SOIL}/types-of-analysis`
const SOIL_SHIPMENTS = `${SOIL}/shipments`
const SOIL_SHIPMENTS_NEW = `${SOIL_SHIPMENTS}/new`
const SOIL_SHIPMENTS_DETAILS = `${SOIL_SHIPMENTS}/:shipmentId`
const SOIL_BATCHS = `${SOIL}/batchs`
const SOIL_BATCHS_NEW = `${SOIL_BATCHS}/new`
const SOIL_BATCHS_DETAILS = `${SOIL_BATCHS}/:batchId`
const SOIL_ANALYSIS = `${SOIL}/analysis`
const SOIL_ANALYSIS_NEW = `${SOIL_ANALYSIS}/new`
const SOIL_ANALYSIS_BATCHS = `${SOIL_ANALYSIS}/:batchId`
const SOIL_ANALYSIS_ATOMIC_ABSORPTION = `${SOIL_ANALYSIS_BATCHS}/atomic-absorption`
const SOIL_ANALYSIS_SPECTROPHOTOMETRY = `${SOIL_ANALYSIS_BATCHS}/spectrophotometry`
const SOIL_ANALYSIS_TITRATION = `${SOIL_ANALYSIS_BATCHS}/titration`
const SOIL_ANALYSIS_RESULT = `${SOIL_ANALYSIS_BATCHS}/results`
const SOIL_ANALYSIS_REPORT = `${SOIL_ANALYSIS_BATCHS}/reports`
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  AUTH,
  PASSWORD_RECOVERY,
  NEW_PASSWORD,
  HOME,
  ADM,
  ADM_USERS,
  BACKOFFICE,
  BACKOFFICE_CLIENTS,
  BACKOFFICE_DETAILS_CLIENT,
  BACKOFFICE_DETAILS_FARM,
  PRECISION,
  PRECISION_HOME,
  PRECISION_HARVESTS,
  PRECISION_SAMPLERS,
  PRECISION_LABORATORYS,
  PRECISION_REQUESTS,
  PRECISION_REQUESTS_NEW,
  PRECISION_REQUESTS_DETAILS,
  PRECISION_EQUIPMENTS,
  PRECISION_EQUIPMENTS_LIST,
  PRECISION_EQUIPMENTS_DETAILS,
  PRECISION_EQUIPMENTS_MAINTENANCE_PLANS,
  PRECISION_REPORTS,
  PRECISION_REPORTS_OVERVIEW,
  PRECISION_REPORTS_COLLECTION_BY_CLIENT,
  PRECISION_REPORTS_PRODUCTIVITY_OF_SAMPLERS,
  PRECISION_REPORTS_DESTINATION_OF_SAMPLES,
  PRECISION_REPORTS_STATUS_PLOTS,
  PRECISION_REPORTS_FARM_LOCATION,
  PRECISION_REPORTS_AREA_LOCATION,
  SOIL,
  SOIL_HOME,
  SOIL_DEPTHS,
  SOIL_TYPES_OF_ANALYSIS,
  SOIL_SHIPMENTS,
  SOIL_SHIPMENTS_NEW,
  SOIL_SHIPMENTS_DETAILS,
  SOIL_BATCHS,
  SOIL_BATCHS_NEW,
  SOIL_BATCHS_DETAILS,
  SOIL_ANALYSIS,
  SOIL_ANALYSIS_NEW,
  SOIL_ANALYSIS_BATCHS,
  SOIL_ANALYSIS_ATOMIC_ABSORPTION,
  SOIL_ANALYSIS_SPECTROPHOTOMETRY,
  SOIL_ANALYSIS_TITRATION,
  SOIL_ANALYSIS_RESULT,
  SOIL_ANALYSIS_REPORT,
  UNAUTHORIZED,
}
