import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getAnalysis({ commit }) {
      try {
        const response = await api.get('/soil/analysis')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async uploadAnalysisFile({ commit }, data) {
      try {
        return await api.post('/soil/analysis/validate', data)
      } catch (error) {
        throw error
      }
    },

    async saveAnalysis({ commit }, data) {
      try {
        return await api.post('/soil/analysis/insert', data)
      } catch (error) {
        throw error
      }
    },

    async getStatusAnalysisByBatch({ commit }, batchId) {
      try {
        const response = await api.get(`/soil/analysis/${batchId}/status`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getSamplesByBatchAndTypeAnalysis({ commit }, data) {
      try {
        const response = await api.get(
          `/soil/analysis/${data.batchId}/samples?analysisId=${data.analysisId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async saveSamplesFromAtomicAbsorptionAnalysis({ commit }, data) {
      try {
        return await api.put(
          `/soil/analysis/${data.batchId}/atomic-absorption`,
          data.samples,
        )
      } catch (error) {
        throw error
      }
    },

    async saveSamplesFromSpectrophotometryAnalysis({ commit }, data) {
      try {
        return await api.put(
          `/soil/analysis/${data.batchId}/spectrophotometry`,
          data,
        )
      } catch (error) {
        throw error
      }
    },

    async saveSamplesFromTitrationAnalysis({ commit }, data) {
      try {
        return await api.put(
          `/soil/analysis/${data.batchId}/titration`,
          data.samples,
        )
      } catch (error) {
        throw error
      }
    },

    async reimportAnalysisFile({ commit }, data) {
      try {
        return await api.delete(
          `/soil/analysis/${data.batchId}/reimport/${data.analysisId}`,
        )
      } catch (error) {
        throw error
      }
    },

    async processDataAnalysis({ commit }, batchId) {
      try {
        return await api.post(`/soil/analysis/${batchId}/process-data`)
      } catch (error) {
        throw error
      }
    },

    async getResultsByBatch({ commit }, batchId) {
      try {
        const response = await api.get(`/soil/analysis/${batchId}/results`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateResultAnalysis({ commit }, data) {
      try {
        return await api.put(
          `/soil/analysis/${data.batchId}/results`,
          data.results,
        )
      } catch (error) {
        throw error
      }
    },

    async getAreasResultsByBatch({ commit }, batchId) {
      try {
        const response = await api.get(
          `/soil/analysis/${batchId}/reports/areas`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getReportDataByBatchAndArea({ commit }, data) {
      try {
        const response = await api.get(
          `/soil/analysis/${data.batchId}/reports/areas/${data.areaId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
