/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */
// Setup module
// ------------------------------

const App = (function () {
  // Utils
  // -------------------------

  //
  // Transitions
  //

  // Disable all transitions
  const transitionsDisabled = function () {
    window.$('body').addClass('no-transitions')
  }

  // Enable all transitions
  const transitionsEnabled = function () {
    window.$('body').removeClass('no-transitions')
  }

  //
  // Detect OS to apply custom scrollbars
  //

  // Custom scrollbar style is controlled by CSS. This function is needed to keep default
  // scrollbars on MacOS and avoid usage of extra JS libraries
  const detectOS = function () {
    const { platform } = window.navigator
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const customScrollbarsClass = 'custom-scrollbars'

    // Add class if OS is windows
    windowsPlatforms.indexOf(platform) !== -1 &&
      window.$('body').addClass(customScrollbarsClass)
  }

  // Sidebars
  // -------------------------

  //
  // On desktop
  //

  // Resize main sidebar
  const sidebarMainResize = function () {
    // Elements
    const sidebarMainElement = window.$('.sidebar-main')
    const sidebarMainToggler = window.$('.sidebar-main-resize')
    const resizeClass = 'sidebar-main-resized'
    const unfoldClass = 'sidebar-main-unfold'

    // Define variables
    const unfoldDelay = 150
    let timerStart
    let timerFinish

    // Toggle classes on click
    sidebarMainToggler.on('click', function (e) {
      sidebarMainElement.toggleClass(resizeClass)
      !sidebarMainElement.hasClass(resizeClass) &&
        sidebarMainElement.removeClass(unfoldClass)
    })

    // Add class on mouse enter
    sidebarMainElement.on('mouseenter', function () {
      clearTimeout(timerFinish)
      timerStart = setTimeout(function () {
        sidebarMainElement.hasClass(resizeClass) &&
          sidebarMainElement.addClass(unfoldClass)
      }, unfoldDelay)
    })

    // Remove class on mouse leave
    sidebarMainElement.on('mouseleave', function () {
      clearTimeout(timerStart)
      timerFinish = setTimeout(function () {
        sidebarMainElement.removeClass(unfoldClass)
      }, unfoldDelay)
    })
  }

  // Toggle main sidebar
  const sidebarMainToggle = function () {
    // Elements
    const sidebarMainElement = window.$('.sidebar-main')
    const sidebarMainRestElements = window.$(
      '.sidebar:not(.sidebar-main):not(.sidebar-component)',
    )
    const sidebarMainDesktopToggler = window.$('.sidebar-main-toggle')
    const sidebarMainMobileToggler = window.$('.sidebar-mobile-main-toggle')
    const sidebarCollapsedClass = 'sidebar-collapsed'
    const sidebarMobileExpandedClass = 'sidebar-mobile-expanded'

    // On desktop
    sidebarMainDesktopToggler.on('click', function (e) {
      e.preventDefault()
      sidebarMainElement.toggleClass(sidebarCollapsedClass)
    })

    // On mobile
    sidebarMainMobileToggler.on('click', function (e) {
      e.preventDefault()
      sidebarMainElement.toggleClass(sidebarMobileExpandedClass)
      sidebarMainRestElements.removeClass(sidebarMobileExpandedClass)
    })
  }

  // Toggle secondary sidebar
  const sidebarSecondaryToggle = function () {
    // Elements
    const sidebarSecondaryElement = window.$('.sidebar-secondary')
    const sidebarSecondaryRestElements = window.$(
      '.sidebar:not(.sidebar-secondary):not(.sidebar-component)',
    )
    const sidebarSecondaryDesktopToggler = window.$('.sidebar-secondary-toggle')
    const sidebarSecondaryMobileToggler = window.$(
      '.sidebar-mobile-secondary-toggle',
    )
    const sidebarCollapsedClass = 'sidebar-collapsed'
    const sidebarMobileExpandedClass = 'sidebar-mobile-expanded'

    // On desktop
    sidebarSecondaryDesktopToggler.on('click', function (e) {
      e.preventDefault()
      sidebarSecondaryElement.toggleClass(sidebarCollapsedClass)
    })

    // On mobile
    sidebarSecondaryMobileToggler.on('click', function (e) {
      e.preventDefault()
      sidebarSecondaryElement.toggleClass(sidebarMobileExpandedClass)
      sidebarSecondaryRestElements.removeClass(sidebarMobileExpandedClass)
    })
  }

  // Toggle right sidebar
  const sidebarRightToggle = function () {
    // Elements
    const sidebarRightElement = window.$('.sidebar-right')
    const sidebarRightRestElements = window.$(
      '.sidebar:not(.sidebar-right):not(.sidebar-component)',
    )
    const sidebarRightDesktopToggler = window.$('.sidebar-right-toggle')
    const sidebarRightMobileToggler = window.$('.sidebar-mobile-right-toggle')
    const sidebarCollapsedClass = 'sidebar-collapsed'
    const sidebarMobileExpandedClass = 'sidebar-mobile-expanded'

    // On desktop
    sidebarRightDesktopToggler.on('click', function (e) {
      e.preventDefault()
      sidebarRightElement.toggleClass(sidebarCollapsedClass)
    })

    // On mobile
    sidebarRightMobileToggler.on('click', function (e) {
      e.preventDefault()
      sidebarRightElement.toggleClass(sidebarMobileExpandedClass)
      sidebarRightRestElements.removeClass(sidebarMobileExpandedClass)
    })
  }

  // Toggle component sidebar
  const sidebarComponentToggle = function () {
    // Elements
    const sidebarComponentElement = window.$('.sidebar-component')
    const sidebarComponentMobileToggler = window.$(
      '.sidebar-mobile-component-toggle',
    )
    const sidebarMobileExpandedClass = 'sidebar-mobile-expanded'

    // Toggle classes
    sidebarComponentMobileToggler.on('click', function (e) {
      e.preventDefault()
      sidebarComponentElement.toggleClass(sidebarMobileExpandedClass)
    })
  }

  // Navigations
  // -------------------------

  // Sidebar navigation
  const navigationSidebar = function () {
    // Define default class names and options
    const navClass = 'nav-sidebar'
    const navItemClass = 'nav-item'
    const navItemOpenClass = 'nav-item-open'
    const navLinkClass = 'nav-link'
    const navSubmenuClass = 'nav-group-sub'
    const navScrollSpyClass = 'nav-scrollspy'
    const navSlidingSpeed = 250

    // Configure collapsible functionality
    window.$(`.${navClass}:not(.${navScrollSpyClass})`).each(function () {
      window
        .$(this)
        .find(`.${navItemClass}`)
        .has(`.${navSubmenuClass}`)
        .children(`.${navItemClass} >.${navLinkClass}`)
        .not('.disabled')
        .on('click', function (e) {
          e.preventDefault()

          // Simplify stuff
          const $target = window.$(this)

          // Collapsible
          if ($target.parent(`.${navItemClass}`).hasClass(navItemOpenClass)) {
            $target
              .parent(`.${navItemClass}`)
              .removeClass(navItemOpenClass)
              .children(`.${navSubmenuClass}`)
              .slideUp(navSlidingSpeed)
          } else {
            $target
              .parent(`.${navItemClass}`)
              .addClass(navItemOpenClass)
              .children(`.${navSubmenuClass}`)
              .slideDown(navSlidingSpeed)
          }

          // Accordion
          if (
            $target.parents(`.${navClass}`).data('nav-type') === 'accordion'
          ) {
            $target
              .parent(`.${navItemClass}`)
              .siblings(`:has(.${navSubmenuClass})`)
              .removeClass(navItemOpenClass)
              .children(`.${navSubmenuClass}`)
              .slideUp(navSlidingSpeed)
          }
        })
    })

    // Disable click in disabled navigation items
    window.$(document).on('click', `.${navClass} .disabled`, function (e) {
      e.preventDefault()
    })
  }

  // Navbar navigation
  const navigationNavbar = function () {
    // Prevent dropdown from closing on click
    window.$(document).on('click', '.dropdown-content', function (e) {
      e.stopPropagation()
    })

    // Disabled links
    window
      .$('.navbar-nav .disabled a, .nav-item-levels .disabled')
      .on('click', function (e) {
        e.preventDefault()
        e.stopPropagation()
      })

    // Show tabs inside dropdowns
    window.$('.dropdown-content a[data-toggle="tab"]').on('click', function () {
      window.$(this).tab('show')
    })
  }

  // Components
  // -------------------------

  // Tooltip
  const componentTooltip = function () {
    window.$('[data-popup="tooltip"]').tooltip({
      boundary: '.page-content',
    })
  }

  // Popover
  const componentPopover = function () {
    window.$('[data-popup="popover"]').popover({
      boundary: '.page-content',
    })
  }

  // "Go to top" button
  const componentToTopButton = function () {
    // Elements
    const toTopContainer = window.$('.content-wrapper')
    const scrollableContainer = window.$('.content-inner')
    const scrollableDistance = 250

    // Append only if container exists
    if (scrollableContainer) {
      // Create button
      toTopContainer.append(
        window.$(
          '<div class="btn-to-top"><button type="button" class="btn btn-dark btn-icon rounded-pill"><i class="icon-arrow-up8"></i></button></div>',
        ),
      )

      // Show and hide on scroll
      const toTopButton = window.$('.btn-to-top')
      const addClassOnScroll = function () {
        toTopButton.addClass('btn-to-top-visible')
      }
      const removeClassOnScroll = function () {
        toTopButton.removeClass('btn-to-top-visible')
      }

      scrollableContainer.on('scroll', function () {
        const scrollpos = scrollableContainer.scrollTop()
        if (scrollpos >= scrollableDistance) {
          addClassOnScroll()
        } else {
          removeClassOnScroll()
        }
      })

      // Scroll to top on click
      window.$('.btn-to-top .btn').on('click', function () {
        scrollableContainer.scrollTop(0)
      })
    }
  }

  // Card actions
  // -------------------------

  // Reload card (uses BlockUI extension)
  const cardActionReload = function () {
    // Elements
    const buttonElement = window.$('[data-action=reload]')
    const overlayContainer = '.card'
    const overlayClass = 'card-overlay'
    const spinnerClass = 'icon-spinner9 spinner text-body'
    const overlayAnimationClass = 'card-overlay-fadeout'

    // Configure
    buttonElement.on('click', function (e) {
      e.preventDefault()

      // Create overlay with spinner
      window
        .$(this)
        .parents(overlayContainer)
        .append(
          window.$(
            `<div class="${overlayClass}"><i class="${spinnerClass}"></i></div>`,
          ),
        )

      // Remove overlay after 2.5s, for demo only
      setTimeout(function () {
        window
          .$(`.${overlayClass}`)
          .addClass(overlayAnimationClass)
          .on('animationend animationcancel', function () {
            window.$(this).remove()
          })
      }, 2500)
    })
  }

  // Collapse card
  const cardActionCollapse = function () {
    // Elements
    const buttonElement = window.$('[data-action=collapse]')
    const cardContainer = '.card'
    const cardCollapsedClass = 'card-collapsed'

    // Configure
    buttonElement.on('click', function (e) {
      e.preventDefault()

      const parentContainer = window.$(this).parents('.card')
      const collapsibleContainer = parentContainer.find('> .collapse')

      if (parentContainer.hasClass(cardCollapsedClass)) {
        parentContainer.removeClass(cardCollapsedClass)
        collapsibleContainer.collapse('show')
      } else {
        parentContainer.addClass(cardCollapsedClass)
        collapsibleContainer.collapse('hide')
      }
    })
  }

  // Remove card
  const cardActionRemove = function () {
    // Elements
    const buttonElement = window.$('[data-action=remove]')
    const cardContainer = '.card'

    // Configure
    buttonElement.on('click', function (e) {
      e.preventDefault()
      window.$(this).parents(cardContainer).slideUp(150)
    })
  }

  // Card fullscreen mode
  const cardActionFullscreen = function () {
    // Elements
    const buttonElement = '[data-action=fullscreen]'
    const buttonClass = 'list-icons-item'
    const buttonContainerClass = 'list-icons'
    const cardFullscreenClass = 'card-fullscreen'
    const collapsedClass = 'collapsed-in-fullscreen'
    const scrollableContainerClass = 'content-inner'
    const fullscreenAttr = 'data-fullscreen'

    // Configure
    window.$(buttonElement).on('click', function (e) {
      e.preventDefault()
      const button = window.$(this)

      // Get closest card container
      const cardFullscreen = button.parents('.card')

      // Toggle required classes
      cardFullscreen.toggleClass(cardFullscreenClass)

      // Toggle classes depending on state
      if (!cardFullscreen.hasClass(cardFullscreenClass)) {
        button.removeAttr(fullscreenAttr)
        cardFullscreen.find(`.${collapsedClass}`).removeClass('show')
        window.$(`.${scrollableContainerClass}`).removeClass('overflow-hidden')
        button
          .parents(`.${buttonContainerClass}`)
          .find(`.${buttonClass}:not(${buttonElement})`)
          .removeClass('d-none')
      } else {
        button.attr(fullscreenAttr, 'active')
        cardFullscreen.removeAttr('style')
        cardFullscreen
          .find('.collapse:not(.show)')
          .addClass(`show ${collapsedClass}`)
        window.$(`.${scrollableContainerClass}`).addClass('overflow-hidden')
        button
          .parents(`.${buttonContainerClass}`)
          .find(`.${buttonClass}:not(${buttonElement})`)
          .addClass('d-none')
      }
    })
  }

  // Misc
  // -------------------------

  // Re-declare dropdown boundary for app container
  const dropdownMenus = function () {
    window.$.fn.dropdown.Constructor.Default.boundary = '.page-content'
  }

  // Dropdown submenus. Trigger on click
  const dropdownSubmenu = function () {
    // All parent levels require .dropdown-toggle class
    window
      .$('.dropdown-menu')
      .find('.dropdown-submenu')
      .not('.disabled')
      .find('.dropdown-toggle')
      .on('click', function (e) {
        e.stopPropagation()
        e.preventDefault()

        const button = window.$(this)

        // Remove "show" class in all siblings
        button
          .parent()
          .siblings()
          .removeClass('show')
          .find('.show')
          .removeClass('show')

        // Toggle submenu
        button
          .parent()
          .toggleClass('show')
          .children('.dropdown-menu')
          .toggleClass('show')

        // Hide all levels when parent dropdown is closed
        button.parents('.show').on('hidden.bs.dropdown', function () {
          window
            .$('.dropdown-submenu .show, .dropdown-submenu.show')
            .removeClass('show')
        })
      })
  }

  // Header elements toggler
  const componentHeaderElements = function () {
    // Toggle visible state of header elements
    window.$('.header-elements-toggle').on('click', function (e) {
      e.preventDefault()
      window
        .$(this)
        .parents('[class*=header-elements-]:not(.header-elements-toggle)')
        .find('.header-elements')
        .toggleClass('d-none')
    })

    // Toggle visible state of footer elements
    window.$('.footer-elements-toggle').on('click', function (e) {
      e.preventDefault()
      window
        .$(this)
        .parents('.card-footer')
        .find('.footer-elements')
        .toggleClass('d-none')
    })
  }

  //
  // Return objects assigned to module
  //

  return {
    // Disable transitions before page is fully loaded
    initBeforeLoad() {
      detectOS()
      transitionsDisabled()
    },

    // Enable transitions when page is fully loaded
    initAfterLoad() {
      transitionsEnabled()
    },

    // Initialize all components
    initComponents() {
      componentTooltip()
      componentPopover()
      componentToTopButton()
      componentHeaderElements()
    },

    // Initialize all sidebars
    initSidebars() {
      sidebarMainResize()
      sidebarMainToggle()
      sidebarSecondaryToggle()
      sidebarRightToggle()
      sidebarComponentToggle()
    },

    // Initialize all navigations
    initNavigations() {
      navigationSidebar()
      navigationNavbar()
    },

    // Initialize all card actions
    initCardActions() {
      cardActionReload()
      cardActionCollapse()
      cardActionRemove()
      cardActionFullscreen()
    },

    // Dropdown submenu
    initDropdowns() {
      dropdownMenus()
      dropdownSubmenu()
    },

    // Initialize core
    initCore() {
      App.initBeforeLoad()
      App.initSidebars()
      App.initNavigations()
      App.initComponents()
      App.initCardActions()
      App.initDropdowns()
    },
  }
})()

// Initialize module
// ------------------------------

// When content is loaded
document.addEventListener('DOMContentLoaded', function () {
  App.initCore()
})

// When page is fully loaded
window.addEventListener('load', function () {
  App.initAfterLoad()
})
