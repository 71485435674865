import api from '@/services/api'

export default {
  state: {
    clientSelected: {
      id: 0,
      inceresId: null,
    },
  },

  mutations: {
    SET_CLIENT_SELECTED(state, client) {
      state.clientSelected = {
        id: client ? client.id : 0,
        inceresId: client ? client.inceresId : null,
      }
    },
  },

  actions: {
    async saveClient({ commit }, client) {
      try {
        return await api.post('/clients', client)
      } catch (error) {
        throw error
      }
    },

    async getClients({ commit }, data) {
      try {
        let url = `/clients`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getClient({ commit }, clientId) {
      try {
        const response = await api.get(`/clients/${clientId}`)

        const client = response && response.data ? response.data : null

        commit('SET_CLIENT_SELECTED', client)

        return client
      } catch (error) {
        throw error
      }
    },

    async updateClient({ commit }, client) {
      try {
        return await api.put(`/clients/${client.id}`, client)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {
    clientSelected: (state) => state.clientSelected,
  },
}
