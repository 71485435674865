import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveShipment({ commit }, shipment) {
      try {
        return await api.post('/soil/shipments', shipment)
      } catch (error) {
        throw error
      }
    },

    async getShipments({ commit }, data) {
      try {
        let url = `/soil/shipments`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getShipment({ commit }, shipmentId) {
      try {
        const response = await api.get(`/soil/shipments/${shipmentId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateShipment({ commit }, shipment) {
      try {
        return await api.put(`/soil/shipments/${shipment.id}`, shipment.samples)
      } catch (error) {
        throw error
      }
    },

    async deleteShipment({ commit }, shipmentId) {
      try {
        return await api.delete(`/soil/shipments/${shipmentId}`)
      } catch (error) {
        throw error
      }
    },

    async getSamples({ commit }, shipmentId) {
      try {
        const response = await api.get(`/soil/shipments/${shipmentId}/samples`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
