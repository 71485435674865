<template>
  <div id="app" class="page-content">
    <sidebar v-show="isLoggedIn && currentRouteName !== 'NotFound'" />
    <div class="content-wrapper">
      <navbar v-show="isLoggedIn && currentRouteName !== 'NotFound'" />
      <div class="content-inner">
        <router-view />
      </div>
      <notification ref="notification" />
    </div>
  </div>
</template>

<script>
import '@/assets/css/icons/fontawesome/styles.min.css'
import '@/assets/css/icons/icomoon/styles.min.css'
import '@/assets/template/default/css/all.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import '@/assets/template/default/js/app'

import Sidebar from '@/components/commons/Sidebar.vue'
import Navbar from '@/components/commons/Navbar.vue'
import Notification from '@/components/commons/Notification.vue'

export default {
  name: 'App',

  components: {
    Sidebar,
    Navbar,
    Notification,
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },

    currentRouteName() {
      return this.$route.name
    },
  },
}
</script>

<style>
.form-check-input {
  margin-top: 0.2rem;
}

.table-report {
  font-size: 0.8rem;
}
</style>
