<template>
  <fragment />
</template>

<script>
import { Fragment } from 'vue-fragment'
import PNotify from 'pnotify/dist/pnotify'
import PNotifyButtons from 'pnotify/dist/pnotify.buttons'

export default {
  name: 'Notification',

  components: { Fragment },

  data() {
    return {
      pnotifybuttons: null,
      pnotify: null,
      addClass: {
        primary: 'bg-success border-success text-white',
        success: 'bg-success border-success text-white',
        warning: 'bg-warning border-warning text-white',
        danger: 'bg-danger border-danger text-white',
        info: 'bg-info border-info text-white',
      },
    }
  },

  methods: {
    primary(text, title = 'Notícia!') {
      this.notification(title, text, this.addClass.primary)
    },

    success(text, title = 'Sucesso!') {
      this.notification(title, text, this.addClass.success)
    },

    warning(text, title = 'Atenção!') {
      this.notification(title, text, this.addClass.warning)
    },

    danger(text, title = 'Erro!') {
      this.notification(title, text, this.addClass.danger)
    },

    info(text, title = 'Informação!') {
      this.notification(title, text, this.addClass.info)
    },

    notification(title, text, addclass) {
      this.pnotifybuttons = PNotifyButtons
      this.pnotify = new PNotify({
        title,
        text,
        addclass,
        buttons: {
          closer: true,
          sticker: true,
          labels: { close: 'Fechar', stick: 'Manter' },
        },
      })
    },
  },
}
</script>
