<template>
  <div
    id="modalAccount"
    class="modal fade"
    tabindex="-1"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Minha Conta</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            title="Fechar"
            @click="$emit('closeModal', false)"
          >
            &times;
          </button>
        </div>
        <form @submit.prevent="updatePassword">
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <p><strong>Nome:</strong> {{ user.name }}</p>
              </div>
              <div class="col-sm-6">
                <p><strong>CPF:</strong> {{ user.login }}</p>
              </div>
              <div class="col-sm-6">
                <p><strong>E-mail:</strong> {{ user.email }}</p>
              </div>
              <div class="col-sm-6">
                <p><strong>Telefone:</strong> {{ user.phone }}</p>
              </div>
            </div>
            <hr />
            <h5>Alterar Senha</h5>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Senha Antiga: <span class="text-danger">*</span></label>
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': $v.oldPassword.$error }"
                  placeholder="Senha Antiga"
                  v-model.trim="$v.oldPassword.$model"
                />
                <span v-if="!$v.oldPassword.minLength" class="invalid-feedback">
                  A senha deve ter pelo menos
                  {{ $v.oldPassword.$params.minLength.min }} caracteres
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Nova Senha: <span class="text-danger">*</span></label>
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': $v.password.$error }"
                  placeholder="Nova Senha"
                  v-model.trim="$v.password.$model"
                />
                <span v-if="!$v.password.minLength" class="invalid-feedback">
                  A senha deve ter pelo menos
                  {{ $v.password.$params.minLength.min }} caracteres
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>
                  Confirme a Nova Senha: <span class="text-danger">*</span>
                </label>
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': $v.confirmPassword.$error }"
                  placeholder="Confirme a Nova Senha"
                  v-model.trim="$v.confirmPassword.$model"
                />
                <span
                  v-if="!$v.confirmPassword.sameAsPassword"
                  class="invalid-feedback"
                >
                  As senhas devem ser idênticas
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
              title="Cancelar"
              @click="$emit('closeModal', false)"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              title="Atualizar"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Atualizar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'AccountModal',

  data() {
    return {
      userId: this.$store.getters.user.id,
      user: {
        name: '',
        login: '',
        email: '',
        phone: '',
      },
      oldPassword: '',
      password: '',
      confirmPassword: '',
      disableButton: false,
    }
  },

  methods: {
    async getUser() {
      try {
        this.user = await this.$store.dispatch('getUser', this.userId)
        window.$('#modalAccount').modal()
      } catch (error) {
        if (error && error.message) {
          this.$root.$children[0].$refs.notification.warning(error.message)
        } else {
          this.$root.$children[0].$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async updatePassword() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch('updatePassword', {
            userId: this.userId,
            oldPassword: this.oldPassword,
            password: this.password,
            confirmPassword: this.confirmPassword,
          })
          this.disableButton = false
          window.$('#modalAccount').modal('hide')
          this.$emit('closeModal', true)
          this.$root.$children[0].$refs.notification.success(response.message)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    oldPassword: {
      required,
      minLength: minLength(6),
    },
    password: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      sameAsPassword: sameAs('password'),
    },
  },

  mounted() {
    this.getUser()
  },
}
</script>
