import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveHarvest({ commit }, harvest) {
      try {
        return await api.post('/precision/harvests', harvest)
      } catch (error) {
        throw error
      }
    },

    async getHarvests({ commit }) {
      try {
        const response = await api.get('/precision/harvests')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getHarvest({ commit }, harvestId) {
      try {
        const response = await api.get(`/precision/harvests/${harvestId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateHarvest({ commit }, harvest) {
      try {
        return await api.put(`/precision/harvests/${harvest.id}`, harvest)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
