import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async saveTypeOfAnalysis({ commit }, typeOfAnalysis) {
      try {
        return await api.post('/soil/typesofanalysis', typeOfAnalysis)
      } catch (error) {
        throw error
      }
    },

    async getTypesOfAnalysis({ commit }, data) {
      try {
        let url = `/soil/typesofanalysis`

        if (data && data.status) {
          url += `?status=${data.status}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getTypeOfAnalysis({ commit }, typeOfAnalysisId) {
      try {
        const response = await api.get(
          `/soil/typesofanalysis/${typeOfAnalysisId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateTypeOfAnalysis({ commit }, typeOfAnalysis) {
      try {
        return await api.put(
          `/soil/typesofanalysis/${typeOfAnalysis.id}`,
          typeOfAnalysis,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
