import api from '@/services/api'

export default {
  state: {
    reloadListMaintenancePlansEquipment: false,
  },

  mutations: {
    SET_RELOAD_LIST_MAINTENANCE_PLANS_EQUIPMENT(state, payload) {
      state.reloadListMaintenancePlansEquipment = payload
    },
  },

  actions: {
    // Equipments

    async saveEquipment({ commit }, equipment) {
      try {
        return await api.post('/precision/equipments', equipment)
      } catch (error) {
        throw error
      }
    },

    async getEquipments({ commit }) {
      try {
        const response = await api.get('/precision/equipments')

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getEquipmentsWithoutAppointment({ commit }) {
      try {
        const response = await api.get(
          '/precision/equipments/without-appointment',
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getEquipment({ commit }, equipmentId) {
      try {
        const response = await api.get(`/precision/equipments/${equipmentId}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateEquipment({ commit }, equipment) {
      try {
        return await api.put(`/precision/equipments/${equipment.id}`, equipment)
      } catch (error) {
        throw error
      }
    },

    // Appointments Equipment

    async saveAppointmentEquipment({ commit }, data) {
      try {
        return await api.post(
          `/precision/equipments/${data.equipmentId}/appointments`,
          data.appointment,
        )
      } catch (error) {
        throw error
      }
    },

    async getAppointmentsEquipment({ commit }, equipmentId) {
      try {
        const response = await api.get(
          `/precision/equipments/${equipmentId}/appointments`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // Maintenance Plan Equipament

    async addMaintenancePlanEquipment({ commit }, data) {
      try {
        return await await api.post(
          `/precision/equipments/${data.equipmentId}/maintenanceplan`,
          data.equipmentMaintenancePlan,
        )
      } catch (error) {
        throw error
      }
    },

    async getMaintenancePlansEquipment({ commit }, equipmentId) {
      try {
        const response = await api.get(
          `/precision/equipments/${equipmentId}/maintenanceplan`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    setReloadListMaintenancePlansEquipment: ({ commit }, status) => {
      commit('SET_RELOAD_LIST_MAINTENANCE_PLANS_EQUIPMENT', status)
    },

    // Register Maintenance Equipament

    async registerMaintenanceEquipment({ commit }, data) {
      try {
        return await await api.post(
          `/precision/equipments/${data.equipmentId}/maintenance`,
          data.maintenance,
        )
      } catch (error) {
        throw error
      }
    },

    async getHistoricMaintenanceEquipment({ commit }, data) {
      try {
        const response = await api.get(
          `/precision/equipments/${data.equipmentId}/maintenance/${data.maintenancePlanId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    // Maintenance Plan

    async saveMaintenancePlan({ commit }, maintenancePlan) {
      try {
        return await api.post(
          '/precision/equipments/maintenanceplan',
          maintenancePlan,
        )
      } catch (error) {
        throw error
      }
    },

    async getMaintenancePlans({ commit }, equipmentId) {
      try {
        let url = '/precision/equipments/maintenanceplan'

        if (equipmentId) {
          url += `?equipmentId=${equipmentId}`
        }

        const response = await api.get(url)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getMaintenancePlan({ commit }, maintenancePlanId) {
      try {
        const response = await api.get(
          `/precision/equipments/maintenanceplan/${maintenancePlanId}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateMaintenancePlan({ commit }, maintenancePlan) {
      try {
        return await api.put(
          `/precision/equipments/maintenanceplan/${maintenancePlan.id}`,
          maintenancePlan,
        )
      } catch (error) {
        throw error
      }
    },
  },

  getters: {
    reloadListMaintenancePlansEquipment: (state) =>
      state.reloadListMaintenancePlansEquipment,
  },
}
