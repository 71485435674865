<template>
  <div class="content d-flex justify-content-center align-items-center">
    <form class="login-form" @submit.prevent="auth">
      <div class="card mb-0">
        <div class="card-body">
          <div class="text-center mb-3">
            <img
              src="@/assets/img/logo.png"
              class="img-fluid mx-auto d-block"
              alt="Logo"
              width="60%"
            />
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <the-mask
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.login.$error }"
              placeholder="CPF"
              v-model="$v.login.$model"
              :mask="'###.###.###-##'"
              :masked="true"
            />
            <div class="form-control-feedback">
              <i class="icon-user text-muted" />
            </div>
            <span v-if="!$v.login.minLength" class="invalid-feedback">
              Formato de CPF inválido (Ex: 000.000.000-00)
            </span>
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': $v.password.$error }"
              placeholder="Senha"
              v-model="$v.password.$model"
            />
            <div class="form-control-feedback">
              <i class="icon-lock2 text-muted" />
            </div>
            <span v-if="!$v.password.minLength" class="invalid-feedback">
              A senha deve ter pelo menos
              {{ $v.password.$params.minLength.min }} caracteres
            </span>
          </div>
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              title="Login"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Login</span>
            </button>
          </div>
          <div class="text-center" title="Esqueceu sua senha?">
            <router-link to="/password-recovery">
              Esqueceu sua senha?
            </router-link>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import PATHS from '@/router/routesMap'

export default {
  name: 'Auth',

  components: {
    TheMask,
  },

  data() {
    return {
      login: '',
      password: '',
      disableButton: false,
    }
  },

  methods: {
    async auth() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          await this.$store.dispatch('auth', {
            login: this.login,
            password: this.password,
          })

          this.disableButton = false
          this.$router.push(PATHS.HOME)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations: {
    login: {
      required,
      minLength: minLength(14),
      maxLength: maxLength(14),
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },

  mounted() {
    if (localStorage.getItem('sessionError')) {
      this.$root.$children[0].$refs.notification.warning(
        localStorage.getItem('sessionError'),
      )
      localStorage.removeItem('sessionError')
    }
  },
}
</script>
